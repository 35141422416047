<template>
  <v-container fluid class="grey lighten-4">
    <v-row justify="center">
      <v-col class="pb-0">
        <v-data-table
          v-model="selected"
          :single-select="false"
          :show-select="show_select"
          item-key="transport_id"
          :headers="tableHeaders"
          :items="transports"
          :height="tableHeight"
          fixed-header
          class="elevation-1 pb-0"
          :search="search"
          :loading="loading"
          loading-text="Ładowanie... Proszę czekać"
          :options.sync="options"
          :server-items-length="total"
          :footer-props="{
            itemsPerPageOptions: listSize,
            showCurrentPage: true,
            disablePagination: disableFooter,
          }"
          ref="my-table"
        >
          <template v-slot:top>
            <v-row no-gutters align="center" justify="space-between">
              <v-col cols="12" sm="12" md="2" lg="2" class="my-4">
                <v-toolbar flat>
                  <v-toolbar-title>
                    <p class="font-weight-medium ma-0">Wszystkie</p>
                    <p class="font-weight-medium ma-0">transporty</p>
                  </v-toolbar-title>
                </v-toolbar>
              </v-col>

              <v-col cols="12" sm="12" md="2" lg="2" class="pa-2 my-2">
                <v-select
                  v-model="status"
                  :items="statuses"
                  label="Status"
                  hide-details
                  item-text="name"
                  item-value="value"
                  dense
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="3" lg="3" class="pa-2 my-2" v-show="selected.length">
                <v-row no-gutters align="center" justify="center">
                  <v-btn color="primary" dark class="mx-1 px-1" @click="editStatus = !editStatus">
                    Status
                  </v-btn>

                  <v-btn color="primary" dark class="mx-1 px-1" @click="editPickup = !editPickup">
                    Odbiór
                  </v-btn>

                  <v-btn
                    color="primary"
                    dark
                    class="mx-1 px-1"
                    @click="editDelivery = !editDelivery"
                  >
                    Dostawa
                  </v-btn>
                </v-row>
              </v-col>

              <v-col cols="12" sm="12" md="4" lg="4" class="pa-2 my-2">
                <v-text-field
                  v-model="search"
                  :label="searchLabel"
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  class="ma-0 pa-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox v-bind="props" v-on="on" :ripple="false"> </v-simple-checkbox>
            <span v-if="!!selected.length" class="float-right">
              {{ selected.length }}
            </span>
          </template>

          <template v-slot:[`item.user_id`]="{ item }">
            <a :href="'/uzytkownicy/' + item.user_id">
              {{ item.name + ' ' + item.surname }}
            </a>
          </template>

          <template v-slot:[`item.s_city`]="{ item }">
            {{ item.s_postcode + ' ' + item.s_city }}
          </template>

          <template v-slot:[`item.r_city`]="{ item }">
            {{ item.r_postcode + ' ' + item.r_city }}
          </template>

          <template v-slot:[`item.weight`]="{ item }">
            {{ `${item.weight}\xa0kg` }}
          </template>

          <template v-slot:[`item.pickup_date`]="{ item }">
            <div class="text-no-wrap">
              {{ item.pickup_date }}
              <v-tooltip top v-if="!item.pickup_date_confirm && item.pickup_date">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isAdmin"
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    x-small
                    icon
                    class="ml-n1 mr-1"
                    @click="confirmDate(item, 'pickup')"
                  >
                    <v-icon>mdi-exclamation-thick</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    x-small
                    icon
                    class="ml-n1 mr-1"
                  >
                    <v-icon>mdi-exclamation-thick</v-icon>
                  </v-btn>
                </template>
                <span> Termin <b>NIE</b> uzgodniony z odbiorcą </span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.date_expected`]="{ item }">
            <div class="text-no-wrap">
              {{ item.date_expected }}
              <v-tooltip top v-if="!item.date_expected_confirm && item.date_expected">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isAdmin"
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    x-small
                    icon
                    class="ml-n1 mr-1"
                    @click="confirmDate(item, 'delivery')"
                  >
                    <v-icon>mdi-exclamation-thick</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                    x-small
                    icon
                    class="ml-n1 mr-1"
                  >
                    <v-icon>mdi-exclamation-thick</v-icon>
                  </v-btn>
                </template>
                <span> Termin <b>NIE</b> uzgodniony z odbiorcą </span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.priceVat`]="{ item }">
            {{
              (
                parseFloat(item.priceVat) + parseFloat(item.pickup_price ? item.pickup_price : 0)
              ).toFixed(2) + '\xa0zł'
            }}
          </template>

          <template v-slot:[`item.money_to_collect`]="{ item }">
            {{ `${item.money_to_collect}\xa0zł` }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="getStatusColor(item.status)" dark>{{
              getStatusName(item.status)
            }}</v-chip>
          </template>

          <template v-slot:[`item.transport_id`]="{ item }">
            <v-tab :to="'/transport/' + item.transport_id"> Zobacz</v-tab>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- edit status dialog -->
    <v-dialog
      v-if="isAdmin && editStatus"
      v-model="editStatus"
      :dark="false"
      :light="true"
      max-width="600px"
    >
      <v-toolbar color="black" :dark="true">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline ma-0 pa-0">Zmień status</v-list-item-title>
            <v-list-item-subtitle class="mt-n4"
              >Tutaj możesz zmienić status transportu</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-spacer />
        <v-btn @click="editStatus = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <TransportStatus :transport="selected" @updateStatus="updateStatus($event)"></TransportStatus>
    </v-dialog>

    <!-- edit delivery date dialog -->
    <v-dialog v-if="isAdmin" v-model="editDelivery" :dark="false" :light="true" max-width="420px">
      <v-toolbar flat color="black" :dark="true">
        <v-toolbar-title>Edytuj date dostawy</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card>
        <v-card-text class="pa-0">
          <v-row v-if="message" class="ma-0">
            <v-col cols="12" sm="12">
              <v-alert type="error" class="ma-0">
                {{ message }}
              </v-alert>
            </v-col>
          </v-row>
          <v-date-picker
            v-model="deliveryDate"
            year-icon="mdi-calendar-blank"
            color="amber darken-1"
            :first-day-of-week="1"
            locale="pl-PL"
            full-width
          ></v-date-picker>

          <v-row class="mx-4 pa-auto">
            <v-checkbox
              v-model="confirm_date"
              label="Data ustalona z klientem"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-row>

          <v-row class="mx-4 pa-auto">
            <v-checkbox
              v-model="notification"
              label="Wyślij powiadomienie w aplikacji (dzwonek)"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-row>

          <v-row class="mx-4 pa-auto">
            <v-checkbox
              v-model="mail"
              label="Wyślij maila z powiadomieniem do klienta"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-row>
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="amber accent-3"
            class="grey--text text--darken-4"
            :x-large="true"
            v-tilt
            outlined
            @click="
              editDelivery = !editDelivery;
              deliveryDate = (' ' + pickupDate).slice(1);
              mail = false;
              confirm_date = false;
              notification = false;
            "
          >
            Anuluj
          </v-btn>

          <v-spacer />

          <v-btn
            color="amber accent-3"
            class="grey--text text--darken-4"
            :x-large="true"
            v-tilt
            :disabled="preventBtn || !deliveryDate"
            @click="changeDate('delivery')"
          >
            Zmień
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit pickup date dialog -->
    <v-dialog v-if="isAdmin" v-model="editPickup" :dark="false" :light="true" max-width="420px">
      <v-toolbar flat color="black" :dark="true">
        <v-toolbar-title>Edytuj date odbioru</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card class="mx-auto pb-1" max-width="800">
        <v-row v-if="message" class="ma-0">
          <v-col cols="12" sm="12">
            <v-alert type="error" class="ma-0">
              {{ message }}
            </v-alert>
          </v-col>
        </v-row>
        <v-date-picker
          v-model="pickupDate"
          year-icon="mdi-calendar-blank"
          color="amber darken-1"
          :first-day-of-week="1"
          locale="pl-PL"
          full-width
        ></v-date-picker>
        <v-row class="mx-4 pa-auto">
          <v-checkbox
            v-model="confirm_date"
            label="Data ustalona z klientem"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-row>
        <v-row class="mx-4 mb-2 pa-auto">
          <v-checkbox
            v-model="mail"
            label="Wyślij maila z powiadomieniem do klienta"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-row>
        <v-row class="mx-4 my-2 pa-auto">
          <v-btn
            color="amber accent-3"
            class="grey--text text--darken-4"
            :x-large="true"
            v-tilt
            outlined
            @click="
              editPickup = !editPickup;
              pickupDate = (' ' + deliveryDate).slice(1);
              mail = false;
              confirm_date = false;
            "
          >
            Anuluj
          </v-btn>
          <v-spacer />
          <v-btn
            color="amber accent-3"
            class="grey--text text--darken-4"
            :x-large="true"
            v-tilt
            :disabled="preventBtn || !pickupDate"
            @click="changeDate('pickup')"
          >
            Zmień
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- search date from dialog -->
    <v-dialog v-model="date_picker_from" :dark="false" :light="true" max-width="420px">
      <v-card class="mx-auto" max-width="800" outlined>
        <v-toolbar flat>
          <v-toolbar-title>Data utworzenia od</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-col cols="12" sm="12" md="12">
          <v-date-picker
            v-model="date_from"
            color="amber darken-1"
            :first-day-of-week="1"
            locale="pl-PL"
            full-width
          ></v-date-picker>
        </v-col>
        <v-row class="ma-0 pa-auto">
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                date_picker_from = !date_picker_from;
                clearDatePicker();
              "
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
              outlined
              >Anuluj</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                date_picker_from = !date_picker_from;
                date_picker_to = !date_picker_to;
              "
              :disabled="!date_from"
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
              >Dalej</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- search date to dialog -->
    <v-dialog v-model="date_picker_to" :dark="false" :light="true" max-width="420px">
      <v-card class="mx-auto" max-width="800" outlined>
        <v-toolbar flat>
          <v-toolbar-title>Data utworzenia do</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-col cols="12" sm="12" md="12">
          <v-date-picker
            v-model="date_to"
            color="amber darken-1"
            :first-day-of-week="1"
            locale="pl-PL"
            full-width
          ></v-date-picker>
        </v-col>
        <v-row class="ma-0 pa-auto">
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                date_picker_to = !date_picker_to;
                clearDatePicker();
              "
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
              outlined
              >Anuluj</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                getDataTransports();
                date_picker_to = !date_picker_to;
              "
              :disabled="!date_to"
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
              >Zatwierdz</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- modal progress circular -->
    <v-dialog v-model="loadingProgress" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5)">
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="amber"> </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarType" :multi-line="true" :timeout="timeout">
      {{ snackbarMessageList[0] ? snackbarMessageList[0].content : '' }}

      <v-btn :color="snackbarButtonColor" text @click="snackbar = false"> Zamknij </v-btn>
    </v-snackbar>

    <!-- confirm dialog -->
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </v-container>
</template>
<script>
  import store from '@/store';
  import { mdiPackageVariant, mdiPin, mdiContacts } from '@mdi/js';

  import axios from 'axios';
  import TransportStatus from '../../components/TransportStatus.vue';
  import ConfirmDialog from '../../components/Confirm.vue';

  export default {
    props: {
      source: String,
    },
    components: {
      TransportStatus,
      ConfirmDialog,
    },
    data: () => ({
      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
      },
      tableHeaders: [
        {
          text: 'Numer transportu',
          align: 'left',
          value: 't_number',
        },
        {
          text: 'Numer własny',
          value: 'extension',
        },
        {
          text: 'Odebrać',
          value: 's_city',
          sortable: false,
        },
        {
          text: 'Dostarczyć',
          value: 'r_city',
          sortable: false,
        },
        {
          text: 'Transport',
          value: 'content',
          sortable: false,
        },
        {
          text: 'Data odbioru',
          value: 'pickup_date',
        },
        {
          text: 'Przewidywana dostawa',
          value: 'date_expected',
        },
        {
          text: 'Kwota brutto',
          value: 'priceVat',
          sortable: false,
        },
        {
          text: 'Kwota pobrania',
          value: 'money_to_collect',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Szczegóły',
          value: 'transport_id',
          sortable: false,
        },
      ],
      isAdmin: store.getters.isAdmin,

      loadingProgress: false,
      snackbar: false,
      snackbarMessageList: [],

      // STATUS:
      status: null,
      statuses: [
        {
          name: 'Niezrealizowane',
          value: null,
        },
        {
          name: 'Wymagana akceptacja',
          value: 0,
        },
        {
          name: 'Oczekuje na magazyn',
          value: 1,
        },
        {
          name: 'Do odbioru u klienta',
          value: 2,
        },
        {
          name: 'Oczekuje na wysyłkę',
          value: 3,
        },
        // {
        //   name: "Termin uzgodniony z odbiorcą",
        //   value: 4,
        // },
        {
          name: 'Wysłano',
          value: 5,
        },
        {
          name: 'Dostarczono',
          value: 6,
        },
        {
          name: 'Oczekuje na pobranie',
          value: 7,
        },
        {
          name: 'Zarchiwizowano',
          value: 8,
        },
        {
          name: 'Anulowano',
          value: 9,
        },
      ],
      get_type: 'byuser',
      byUser_id: null,

      editedTransport: {},
      editStatus: false,

      transports: [],
      prevTransports: {
        page: -1,
        transports: [],
      },
      nextTransports: {
        page: 1,
        transports: [],
      },
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 100,
        sortBy: ['t_number'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      searchLabel: '',
      search: null,
      total: 0,
      disableFooter: true,
      listSize: [10, 20, 50, 100, 200],

      date_from: null,
      date_to: null,
      date_picker_from: false,
      date_picker_to: false,

      selected: [],
      isSelected: true,
      show_select: false,

      editDelivery: false,
      deliveryDate: null,
      editPickup: false,
      pickupDate: null,
      mail: false,
      confirm_date: false,
      notification: false,

      preventBtn: false,
      message: null,
    }),
    computed: {
      tableHeight: function () {
        return window.innerHeight * 0.7;
      },
      timeout: function () {
        return this.snackbarMessageList[0] && this.snackbarMessageList[0].timeout
          ? this.snackbarMessageList[0].timeout
          : 6000;
      },
      snackbarType: function () {
        return this.snackbarMessageList[0] && this.snackbarMessageList[0].type == 'error'
          ? 'red'
          : '';
      },
      snackbarButtonColor: function () {
        return this.snackbarMessageList[0] && this.snackbarMessageList[0].type == 'error'
          ? ''
          : 'amber';
      },
    },
    watch: {
      status: function () {
        this.options.page = NaN;
        this.selected = [];
      },
      search: function (str) {
        let ref = this;
        setTimeout(function () {
          if (ref.search === (' ' + str).slice(1)) {
            ref.options.page = NaN;
          }
        }, 600);
      },
      '$route.params.id': function (next) {
        if (isNaN(next)) {
          this.byUser_id = null;
          this.getDataTransports();
        }
      },
      'options.sortBy': function (next, before) {
        if (next !== before) {
          this.options.page = NaN;
        }
      },
      'options.itemsPerPage': function (next, before) {
        if (next !== before) {
          this.options.page = NaN;
        }
      },
      'options.page': async function (next, before) {
        var table = this.$refs['my-table'];
        var wrapper = table.$el.querySelector('div.v-data-table__wrapper');

        this.$vuetify.goTo(table); // to table
        this.$vuetify.goTo(table, { container: wrapper }); // to header

        if (isNaN(before)) {
          this.total = 0;
          this.getDataTransports();
        } else if (isNaN(next)) {
          this.options.page = 1;
        } else if (next === before + 1) {
          this.disableFooter = true;
          this.prevTransports.transports = this.transports;
          this.prevTransports.page = before;

          this.transports = this.nextTransports.transports;

          this.nextTransports.page = next + 1;
          let newNext = await this.getTransports(next + 1);
          this.nextTransports.transports = newNext.transports;
          this.disableFooter = false;
        } else if (next === before - 1) {
          this.disableFooter = true;
          this.nextTransports.transports = this.transports;
          this.nextTransports.page = before;

          this.transports = this.prevTransports.transports;

          this.prevTransports.page = next - 1;
          let newPrev = await this.getTransports(next - 1);
          this.prevTransports.transports = newPrev.transports;
          this.disableFooter = false;
        }
      },
      'snackbarMessageList.length': function (val) {
        if (val) {
          let that = this;
          setTimeout(function () {
            that.snackbar = true;
          }, 100);
        }
      },
      snackbar: function (val) {
        if (!val) {
          this.snackbarMessageList.shift();
        }
      },
    },
    methods: {
      changeDate(type) {
        this.preventBtn = true;

        axios({
          url: this.$store.state.url + '/api/transport/modify/date',
          data: {
            token: this.$store.state.token,
            id: this.selected.map(value => {
              return value.transport_id;
            }),
            date: type == 'delivery' ? new Date(this.deliveryDate) : new Date(this.pickupDate),
            type: type,
            mail: this.mail,
            confirm_date: this.confirm_date,
            notification: type == 'delivery' ? this.notification : undefined,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              // date to string
              let date = type == 'delivery' ? new Date(this.deliveryDate) : new Date(this.pickupDate);
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              let dt = date.getDate();

              if (dt < 10) dt = '0' + dt;
              if (month < 10) month = '0' + month;

              if (type == 'delivery') {
                this.selected.forEach(elem => {
                  elem.date_expected = dt + '.' + month + '.' + year;
                  elem.date_expected_confirm = this.confirm_date;
                });
                this.editDelivery = false;
                this.deliveryDate = `${year}-${month}-${dt}`;
              } else {
                this.selected.forEach(elem => {
                  elem.pickup_date = dt + '.' + month + '.' + year;
                  elem.pickup_date_confirm = this.confirm_date;
                });
                this.editPickup = false;
                this.pickupDate = `${year}-${month}-${dt}`;
              }

              this.preventBtn = false;
              this.message = null;
              this.mail = false;
              this.confirm_date = false;
              this.notification = false;

              this.messageM({
                content: resp.data.message,
              });
              //this.clearSelected();
            } else {
              this.message = resp.data.message;
              let that = this;
              setTimeout(() => {
                that.preventBtn = false;
              }, 200);
            }
          })
          .catch(() => {
            let that = this;
            setTimeout(() => {
              that.preventBtn = false;
            }, 200);
          });
      },
      clearSelected() {
        this.selected = [];
      },
      updateStatus(status) {
        // STATUS:
        if (status == 9 || status == 8) {
          this.disableFooter = true;

          // remove from transports
          if (this.transports.indexOf(this.selected[0]) != -1) {
            this.transports.splice(this.transports.indexOf(this.selected[0]), 1);
          } else if (this.nextTransports.transports.indexOf(this.selected[0]) != -1) {
            this.nextTransports.transports.splice(
              this.nextTransports.transports.indexOf(this.selected[0]),
              1
            );
          } else if (this.prevTransports.transports.indexOf(this.selected[0]) != -1) {
            this.prevTransports.transports.splice(
              this.prevTransports.transports.indexOf(this.selected[0]),
              1
            );
          } else {
            this.disableFooter = false;
            this.editStatus = false;
            return;
          }
          this.total -= 1;
          this.selected = [];

          if (!this.transports.length) {
            // usunieto z transports
            this.options.page = this.options.page - 1 ? this.options.page - 1 : 1;
          } else if (
            this.transports.length != this.options.itemsPerPage &&
            this.nextTransports.transports.length
          ) {
            // usunieto z transports
            this.transports.push(this.nextTransports.transports.shift());

            let af = async vueObject => {
              let newNext = await vueObject.getTransports(vueObject.options.page + 1);
              vueObject.nextTransports.transports = newNext.transports;

              vueObject.disableFooter = false;
            };
            af(this);
          } else if (
            this.prevTransports.transports.length &&
            this.prevTransports.transports.length != this.options.itemsPerPage
          ) {
            // usunieto z prevTransports
            this.prevTransports.transports.push(this.transports.shift());

            if (this.nextTransports.transports.length) {
              this.transports.push(this.nextTransports.transports.shift());

              let af = async vueObject => {
                let newNext = await vueObject.getTransports(vueObject.options.page + 1);
                vueObject.nextTransports.transports = newNext.transports;

                vueObject.disableFooter = false;
              };
              af(this);
            }
          } else {
            // usunieto z nextTransports
            let af = async vueObject => {
              let newNext = await vueObject.getTransports(vueObject.options.page + 1);
              vueObject.nextTransports.transports = newNext.transports;

              vueObject.disableFooter = false;
            };
            af(this);
          }
          this.editStatus = false;
        } else {
          const needExpectedUpdate = [];
          this.selected.forEach(elem => {
            // STATUS
            if (!elem.date_expected && status > 2) {
              needExpectedUpdate.push(elem.transport_id);
            }

            elem.status = status;
            // STATUS:
            if (status > 2) {
              elem.pickup_date_confirm = 1;
            }
            if (status > 4) {
              elem.date_expected_confirm = 1;
            }
          });

          // popraw date_expected gdzie trzeba
          if (needExpectedUpdate.length) {
            const af = async vueObject => {
              const refreshTransports = await vueObject.getTransports(vueObject.options.page);
              refreshTransports.transports
                .filter(elem => {
                  return needExpectedUpdate.includes(elem.transport_id);
                })
                .forEach(elem => {
                  const found = vueObject.selected.find(
                    element => element.transport_id === elem.transport_id
                  );
                  if (found !== undefined) {
                    found.date_expected = elem.date_expected;
                  }
                });
            };
            af(this);
          }

          this.editStatus = false;
          this.messageM({
            content: 'Status transportu/ów został zmieniony',
          });
        }
      },
      clearDatePicker() {
        this.date_from = null;
        this.date_to = null;
        this.$router.replace({ query: null });
      },
      getStatusColor: function (status) {
        let r;
        // STATUS:
        switch (status) {
          // case 0: r = 'red'; break;
          // case 1: r = 'black'; break;
          // case 2: r = 'grey'; break;
          // case 3: r = 'blue'; break;
          // case 4: r = 'green'; break;
          // case 5: r = 'silver'; break;
          // case 6: r = 'indigo'; break;
          // case 7: r = 'teal'; break;
          // case 8: r = 'amber'; break;
          // case 9: r = 'red darken-4'; break;
          case 0:
            r = 'red';
            break;
          case 1:
            r = 'black';
            break;
          case 2:
            r = 'grey';
            break;
          case 3:
            r = 'teal';
            break;
          case 4:
            r = 'green';
            break;
          case 5:
            r = 'amber';
            break;
          case 6:
            r = 'blue';
            break;
          case 7:
            r = 'indigo';
            break;
          case 8:
            r = 'silver';
            break;
          case 9:
            r = 'red darken-4';
            break;
        }
        return r;
      },
      getStatusName: function (status) {
        let r;
        // STATUS:
        switch (status) {
          case 0:
            r = 'Wymagana akceptacja';
            break;
          case 1:
            r = 'Oczekuje na magazyn';
            break;
          case 2:
            r = 'Do odbioru u klienta';
            break;
          case 3:
            r = 'Oczekuje na wysyłkę';
            break;
          case 4:
            r = 'Termin uzgodniony z odbiorcą';
            break;
          case 5:
            r = 'Wysłano';
            break;
          case 6:
            r = 'Dostarczono';
            break;
          case 7:
            r = 'Oczekuje na pobranie';
            break;
          case 8:
            r = 'Zarchiwizowano';
            break;
          case 9:
            r = 'Anulowano';
            break;
        }
        return r;
      },
      getTransports(n) {
        if (n < 1) {
          return {
            transports: [],
            total: 0,
          };
        }

        // eslint-disable-next-line no-unused-vars
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let from = (n - 1) * itemsPerPage;
        let to = n * itemsPerPage;

        let search;
        if (this.search) {
          search = {
            t_number: this.search,
            client: this.search,
            postcodes: this.search,
            cities: this.search,
            extension: this.search,
          };
        } else {
          search = null;
        }

        let date = new Date(this.date_to);
        date.setDate(date.getDate() + 1);
        let month_to = '' + (date.getMonth() + 1);
        let day_to = '' + date.getDate();
        let year_to = date.getFullYear();

        if (month_to.length < 2) month_to = '0' + month_to;
        if (day_to.length < 2) day_to = '0' + day_to;

        return axios({
          url: this.$store.state.url + '/api/transport/search',
          data: {
            token: this.$store.state.token,
            sortBy: sortBy,
            sortDesc: sortDesc,
            from: from,
            to: to,
            search: search,
            status: this.status,
            by_user: this.byUser_id,
            date_from: this.date_from,
            date_to: this.date_to ? [year_to, month_to, day_to].join('-') : null,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              for (let [, t] of resp.data.transports.entries()) {
                if (t.packages) {
                  const pack = JSON.parse(t.packages);

                  let sumWeight = 0;
                  for (const pck of pack)
                    sumWeight +=
                      (pck.quantity ? parseInt(pck.quantity) : 1) *
                      (pck.packages
                        ? pck.packages.reduce((acc, elem) => acc + parseFloat(elem.weight), 0)
                        : pck.weight || 0);

                  t.weight = sumWeight.toFixed(2);
                }

                if (t.date_expected) {
                  let date = new Date(t.date_expected);

                  if (date.getDate()) {
                    let year = date.getFullYear();
                    let month = date.getMonth() + 1;
                    let dt = date.getDate();

                    if (dt < 10) {
                      dt = '0' + dt;
                    }
                    if (month < 10) {
                      month = '0' + month;
                    }
                    t.date_expected = dt + '.' + month + '.' + year;
                  } else {
                    t.date_expected = null;
                  }
                }

                if (t.pickup_date) {
                  let date = new Date(t.pickup_date);

                  if (date.getDate()) {
                    let year = date.getFullYear();
                    let month = date.getMonth() + 1;
                    let dt = date.getDate();

                    if (dt < 10) {
                      dt = '0' + dt;
                    }
                    if (month < 10) {
                      month = '0' + month;
                    }
                    t.pickup_date = dt + '.' + month + '.' + year;
                  } else {
                    t.pickup_date = null;
                  }
                }

                if (t.date_created) {
                  let date = new Date(t.date_created);

                  if (date.getDate()) {
                    let year = date.getFullYear();
                    let month = date.getMonth() + 1;
                    let dt = date.getDate();
                    let hr = date.getHours();
                    let min = date.getMinutes();

                    if (dt < 10) dt = '0' + dt;
                    if (month < 10) month = '0' + month;
                    if (hr < 10) hr = '0' + hr;
                    if (min < 10) min = '0' + min;

                    t.date_created = dt + '.' + month + '.' + year + ' ' + hr + ':' + min;
                  } else {
                    t.date_created = null;
                  }
                }
              }

              return resp.data;
            } else {
              return {
                transports: [],
                total: 0,
              };
            }
          })
          .catch();
      },
      async getDataTransports() {
        this.disableFooter = true;
        this.loading = true;
        let newData = await this.getTransports(1);
        this.transports = newData.transports;
        this.total = newData.total;
        this.loading = false;

        this.nextTransports.page = 2;
        let newNext = await this.getTransports(2);
        this.nextTransports.transports = newNext.transports;

        this.prevTransports.page = 0;
        let newPrev = await this.getTransports(0);
        this.prevTransports.transports = newPrev.transports;
        this.disableFooter = false;
      },
      confirmDate: async function (item, type = 'delivery') {
        this.resetSnackbar();

        let confirm = await this.$refs.confirmDialog.open(
          'Potwierdzenie daty',
          'Czy data jest ustalona z klientem?',
          {
            color: 'black',
            cancelButtonText: 'Nie',
            acceptButtonText: 'Tak',
          }
        );
        if (!confirm) {
          return;
        }

        this.loadingProgress = true;

        axios({
          url: this.$store.state.url + '/api/transport/modify/confirmDate',
          data: {
            token: this.$store.state.token,
            transport_id: item.transport_id,
            type: type,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) {
              if (type == 'delivery') {
                item.date_expected_confirm = 1;
              } else {
                item.pickup_date_confirm = 1;
              }
            } else {
              this.messageM({
                content: resp.data.message,
                type: 'error',
              });
            }

            this.loadingProgress = false;
          })
          .catch(() => {
            this.messageM({
              content: 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });

            let that = this;
            setTimeout(function () {
              that.loadingProgress = false;
            }, 1000);
          });

        return [item, type];
      },
      messageM: function (message, clear = false) {
        if (clear) {
          this.resetSnackbar();
          this.snackbarMessageList = [message];
        } else {
          this.snackbarMessageList.push(message);
        }
      },
      resetSnackbar: function () {
        this.snackbarMessageList = [];
        this.snackbar = false;
      },
    },
    created() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) dt = '0' + dt;
      if (month < 10) month = '0' + month;

      this.deliveryDate = `${year}-${month}-${dt}`;
      this.pickupDate = `${year}-${month}-${dt}`;

      if (this.isAdmin) {
        this.searchLabel = 'kod pocztowy, miejscowość, klient lub numer własny';
        this.get_type = 'all';
        this.byUser_id = this.$route.params.id ? decodeURIComponent(this.$route.params.id) : null;
        this.tableHeaders.splice(1, 0, {
          text: 'Klient',
          value: 'user_id',
        });
        this.tableHeaders.splice(4, 0, {
          text: 'Waga',
          value: 'weight',
          sortable: false,
        });
        this.show_select = true;
      } else {
        this.searchLabel = 'kod pocztowy, miejscowość lub numer własny';
        this.tableHeaders.splice(2, 1);
        this.tableHeaders.splice(6, 0, {
          text: 'Data utworzenia',
          value: 'date_created',
          sortable: false,
        });
        this.tableHeaders.splice(1, 0, {
          text: 'Klient',
          value: 'r_name',
        });
      }

      this.search = this.$route.query.search ? decodeURIComponent(this.$route.query.search) : null;
      this.date_from = this.$route.query.from ? decodeURIComponent(this.$route.query.from) : null;
      this.date_to = this.$route.query.to ? decodeURIComponent(this.$route.query.to) : null;

      this.getDataTransports();
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style>
  .text-start {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
</style>
